import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import Carousel3d from "vue-carousel-3d";
import VueTyperPlugin from "vue-typer";
import VueClipboard from "vue-clipboard2";
import AOS from "aos";
import VueMarqueeSlider from "vue-marquee-slider";
import "aos/dist/aos.css";

AOS.init();
Vue.use(Carousel3d);
Vue.use(VueTyperPlugin);
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(VueMarqueeSlider);

Vue.config.productionTip = false;

export const bus = new Vue();

//
router.beforeEach((to, from, next) => {
  if (to.name != "home") return next();
  if (to.params.country) return next();

  next({
    name: store.getters.getCountry?.route ?? to.name,
    params: { country: store.getters.getCountry?.alpha3?.toLowerCase() },
  });
});

//set the title of each webpage on routing and start page from the top
router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title =
    toRoute.meta && toRoute.meta.title
      ? toRoute.meta.title
      : "Reliable way to pay and get paid - 1app";
  window.scroll(0, 0);

  next();
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
